<template>
  <span v-if="hasMismatchedUnitStayDates" class="font-semibold">
    <AppDateRange
      :from-date="bookingUnit.checkInDate"
      :to-date="bookingUnit.checkOutDate"
    />
  </span>

  <div class="flex justify-between">
    <div class="break-anywhere">
      <div class="font-semibold lg:flex">
        {{ bookingUnit.unitName }}

        <span
          v-if="bookingUnit.bookingUnitWayToSell"
          class="ml-1 font-normal text-neutral-500"
        >
          ({{ bookingUnit.bookingUnitWayToSell.wayToSellName }})
        </span>
      </div>

      <span v-if="bookingUnitMealsDisplayText">
        {{ bookingUnitMealsDisplayText }}
      </span>

      <div
        v-if="bookingUnit.bookingUnitOffer"
        class="text-sm text-neutral-600 first-letter:capitalize"
      >
        {{
          $t('thisWasBookedAsAPartOfOffer', {
            offerName: bookingUnit.bookingUnitOffer.offerName,
          })
        }}

        <div v-if="bookingUnit.bookingUnitOffer.offerDescription">
          {{ bookingUnit.bookingUnitOffer.offerDescription }}
        </div>
      </div>
    </div>

    <div>
      {{
        $n(bookingUnit.rate, {
          style: 'currency',
          currency: property.currencyCode,
        })
      }}
    </div>
  </div>

  <div class="flex flex-col text-sm capitalize">
    <div>{{ occupancyDisplayTextWithOccupantAges }}</div>

    <div v-if="bookingUnit.leadGuestName" class="break-anywhere">
      {{ $t('mainGuest', { guestName: bookingUnit.leadGuestName }) }}
    </div>

    <BookingConfirmationSummaryUnitBedConfiguration
      v-if="bookingUnit.twinDoubleBedOption"
      :twin-double-bed-option="bookingUnit.twinDoubleBedOption"
    />
  </div>

  <BookingConfirmationSummaryUnitContingencyCharge
    v-if="contingencyCharge"
    :contingency-charge
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { ActiveBookingUnit } from '@/booking/unit/active/active-booking-unit';
import { useBookingUnitMealsDisplayText } from '@/booking/unit/meal/booking-unit-meals-display-text.composable';
import { useBookingConfirmationStore } from '@/booking-confirmation-page/booking-confirmation.store';
import BookingConfirmationSummaryUnitBedConfiguration from '@/booking-confirmation-page/summary/unit/BookingConfirmationSummaryUnitBedConfiguration.vue';
import BookingConfirmationSummaryUnitContingencyCharge from '@/booking-confirmation-page/summary/unit/contingency-charge/BookingConfirmationSummaryUnitContingencyCharge.vue';
import { useOccupancy } from '@/occupancy/occupancy.composable';
import AppDateRange from '@/ui/app-date-range/AppDateRange.vue';

const props = defineProps<{
  bookingUnit: ActiveBookingUnit;
}>();

const { property, hasMismatchedUnitStayDates } = storeToRefs(
  useBookingConfirmationStore(),
);

const { generateBasicDisplayTextWithOccupantAges } = useOccupancy();
const occupancyDisplayTextWithOccupantAges = computed(() =>
  generateBasicDisplayTextWithOccupantAges(props.bookingUnit.occupancy),
);

const { bookingUnitMealsDisplayText } = useBookingUnitMealsDisplayText(
  props.bookingUnit.bookingUnitMeals,
);

const contingencyCharge = computed(() => props.bookingUnit.contingencyCharge);
</script>
